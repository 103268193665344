import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  BusinessDataContext,
  TempDataContext,
  FormDataContext,
} from "../../contexts/Contexts";
import ResponseModal from "../../components/ResponseModal";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Profile-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions();
  };

  const fetchTransactions = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Withdrawing fund...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("amount", String(formData.amount));

    urlencoded.append(
      "narration",
      formData.narration ? String(formData.narration) : ""
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,

      body: urlencoded,
    };
    fetch(
      localStorage.getItem("apiURL") + "withdrawals",

      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (data.message === "Token Expired") {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "modalOpen", value: true },
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        {/* <ResponseModal isOpen={true}/> */}
        <Row className="justify-content-center align-content-center ">
          <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Withdraw Fund</b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <div className="account-card-content">
                  {formData.error && (
                    <Alert color="danger">{formData.error}</Alert>
                  )}
                  <AvForm
                    className="form-horizontal m-t-30"
                    onValidSubmit={handleSubmit}
                  >
                    <AvField
                      label="Amount"
                      name="amount"
                      value={formData.amount}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Amount"
                      type="number"
                      errorMessage="Enter valid amount"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Only valid amount",
                        },
                      }}
                    />

                    <AvField
                      label="Narration (optional)"
                      name="narration"
                      value={formData.narration}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      placeholder="Narration (Avoid words like bitcoin, dollar purchase etc)"
                      type="text"
                      errorMessage="Enter valid narration"
                    />

                    <Button
                      className="btn-primary w-md waves-effect waves-light "
                      type="submit"
                    >
                      Withdraw Fund
                    </Button>
                    <p className="text-danger mt-2">
                      Withdrawals takes a maximum of 4 hours to process
                    </p>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
